<template>
  <h1>Edit Announcement</h1>
  <AnnouncementForm :editing-existing="true" :announcement-uuid="announcementUuid"/>
</template>

<script>
import AnnouncementForm from "@/views/SuperUserViews/Announcements/AnnouncementForm.vue";

export default {
  name: "AnnouncementEdit",
  components: {AnnouncementForm},
  props: {
    announcementUuid: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped>

</style>